import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import { RichTextSection } from "../templates/show-item"

const AboutPage = ({ data }) => {
  const { contact } = data.siteData.nodes[0]
  return (
    <Layout>
      <SiteMetadata title="Contact" description="Contact Tony Scialli" />
      <div className="bg-gray-100">
        <div className="container py-12 lg:pb-16">
          <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-5">
            Contact
          </h1>
          <div className="flex-1 md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <RichTextSection text={contact} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query ContactQuery {
    siteData: allContentfulSiteData {
      nodes {
        contact {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`
